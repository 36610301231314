import React from "react"
import { Navigation, Blog, Footer, Hero } from "../components/Marketing"
import { Seo } from "../components/Seo/Seo"

export default () => {
  return (
    <>
      <Seo
        title={`Nous contacter | L'équipe du Temple du Haïku`}
        description={``}
      />
      <Navigation />
      <Hero
        theme={{ as: "h2", value: <>Informations de contact</> }}
        title={{
          as: "h1",
          value: (
            <>
              Contacter l'équipe du{" "}
              <span className="text-green-600">Temple du Haïku</span>
            </>
          ),
        }}
        description={{
          value: (
            <>
              Que vous ayez envie de nous faire part de vos retour et de nous
              proposer des améliorations, ou que vous souhaitiez tout simplement
              entrer en contacte avec nous, vous retrouverez ci-dessous les
              moyens pour y parvenir.
              <br />
              <br />
              Nous répondons à tous les mails le plus rapidement possible.
              <br />
              Au plaisir d'avoir de vos nouvelles !
            </>
          ),
        }}
      />

      <div className="bg-white">
        <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                Des retours à faire ?
              </h2>
              <div className="mt-3">
                <p className="text-lg text-gray-500">
                  Que vous ayez eu des problèmes pour utiliser notre
                  application, ou que vous avez des suggestions à nous partager,
                  vous pouvez le faire en utilisant ces contacts ci dessous.
                </p>
              </div>
              <div className="mt-9">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-6 h-6 text-blue-400"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </div>
                  <a
                    target="_blank"
                    href="https://twitter.com/temple_du_haiku/status/1471244358267588617"
                    className="ml-3 text-base text-blue-400"
                  >
                    <p>Nous contacter sur Twitter</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 md:mt-0">
              <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                Envie de discuter avec nous ?
              </h2>
              <div className="mt-3">
                <p className="text-lg text-gray-500">
                  Pour tout autre sujet qui ne concerne pas directement
                  l'application, vous pouvez le faire en nous contactant avec
                  les informations ci-dessous.
                </p>
              </div>
              <div className="mt-9">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-6 h-6 text-blue-400"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </div>
                  <a
                    target="_blank"
                    href="https://twitter.com/temple_du_haiku/status/1471244358267588617"
                    className="ml-3 text-base text-blue-400"
                  >
                    <p>Nous contacter sur Twitter</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
